import './app.scss'
import Rellax from 'rellax'
import $ from 'jquery';

if (document.querySelector('.rellax') !== null) {
    new Rellax('.rellax')
}

$(function() {
   $('.nav-toggle, .cloak').click(function() {
       $('body').toggleClass('menu-open')
   })
});